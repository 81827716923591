import Vue from "vue";
import Router from "vue-router";
//import NotFound from './views/NotFound'
//import Forbidden from './views/Forbidden'

//import Home from './views/Home'
import store from "./store";
//import permisosMiddleware from './middleware/permisos';


Vue.use(Router);





let router = new Router({
  //mode: "hash",
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          name: "dashboard",

          path: "dashboard",
          component: () => import("@/views/dashboard/Dashboard"),
          meta: {
            titleApp: "Dashboard",
            requireAuth: true,
          },
        },
        {
          name: "landing",

          path: "",
          component: () => import("@/views/dashboard/Landing"),
          meta: {
            titleApp: "Dashboard",
            requireAuth: true,
          },
        },

        {
          name: "carrito_detalle_pedido",
          titleApp: "Compra detalle del Pedido",
          path: "carrito_detalle_pedido",
          component: () => import("@/views/carrito/DetallePedido"),
          meta: {
            titleApp:
            "<span  class='pa-left'></span>"+
            "Detalle de Pedido",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES],
          },
        },

        {
          name: "carrito_detalle_pedido_anmac",
          titleApp: "Compra detalle del Pedido",
          path: "carrito_detalle_pedido_anmac",
          component: () => import("@/views/carrito/DetallePedidoAnmac"),
          meta: {
            titleApp:
            "<span  class='pa-left'></span>"+
            "Detalle de Pedido ANMaC",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES],
          },
        },
        {
          name: "carrito_detalle_facturacion",
          titleApp: "Compra detalle del Pedido",
          path: "carrito_detalle_facturacion",
          component: () => import("@/views/carrito/DetalleFacturacion"),
          meta: {
            titleApp:
            "<span  class='pa-left'></span>"+
            "Detalle Facturación",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES],
          },
        },

        // --------------- Flujo Elementos Registrales INICIO ---------------
        {
          name: "elementos_registrales",
          titleApp: "Venta Elementos Registrales - Selección de Productos",
          path: "elementos_registrales",
          component: () => import("@/views/portal/ElementosRegistrales"),
          meta: {
            titleApp: "<span class='darken-1'>Dashboard</span>"+
             "<span  class='darken-1 pa-left'>/</span>"+
            "<span  class='pa-left'></span>"+
            "Comprar Elementos Registrales",
            //middleware: [propertiesSap],
            requireAuth:true,
            store: true,
            //permiso:["comprarElementosRegistrales"],
          },
        },

        {
          name: "pedido_finalizado",
          path: "PedidoFinalizado",
          component: () => import("@/views/portal/PedidoFinalizado"),
          meta: {
            titleApp: "Venta Elementos Registrales - Pedido Finalizado",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES],
          },
        },
        // --------------- Flujo Elementos Registrale FIN ----------------------
        // --------------- Flujo Comprar Elementos Aduana INICIO ---------------
        {
          name: "elementos_aduana_compra",
          path: "elementos_aduana_compra",
          component: () => import("@/views/portal/ElementosAduanaCompra"),
          meta: {
            titleApp: "<span class='darken-1'>Dashboard</span>"+
            "<span  class='darken-1 pa-left'>/</span>"+
            "<span  class='pa-left'></span>"+
            "Comprar Elementos Aduana",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_ADUANA],
          },
        },

        {
          name: "elementos_aduana_compra_pedido_finalizado",
          path: "elementos_aduana_compra_pedido_finalizado",
          component: () => import("@/views/portal/PedidoFinalizado"),
          meta: {
            titleApp: "Comprar Elementos Aduana - Pedido Finalizado",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_ADUANA],
          },
        },
        // --------------- Flujo Comprar Elementos Aduana FIN --------------
        // --------------- Flujo Vender Elementos Aduana INICIO ------------
        {
          name: "elementos_aduana_venta",
          path: "elementos_aduana_venta/:CardCode?",
          component: () => import("@/views/portal/ElementosAduanaVenta"),
          meta: {
            titleApp: "<span class='darken-1'>Vender</span>"+
            "<span  class='darken-1 pa-left'>/</span>"+
            "<span  class='pa-left'></span>"+
            "Vender Elementos Aduana",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_ADUANA],
          },
        },
        {
          name: "elementos_aduana_venta_seleccion",
          path: "elementos_aduana_venta_seleccion",
          component: () => import("@/views/portal/ElementosAduanaCompra"),
          meta: {
            titleApp: "Vender Elementos Aduana - Selección de Productos",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:'vender_elementos_aduana',
          },
        },

        {
          name: "elementos_aduana_venta_pedido_finalizado",
          path: "elementos_aduana_venta_pedido_finalizado",
          component: () => import("@/views/portal/PedidoFinalizado"),
          meta: {
            titleApp: "Vender Elementos Aduana - Pedido Finalizado",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:'vender_elementos_aduana',

          },
        },
        // --------------- Flujo Vender Elementos Aduana FIN --------------

        // --------------- Flujo Adquirir Saldo Cta Cte INICIO ---------------
        {
          name: "adquirir_saldo_cta_cte",
          titleApp: "Adquirir Saldo Cuenta Corriente - Selección de Productos",
          path: "adquirir_saldo_cta_cte",
          component: () => import("@/views/portal/AdquirirSaldoCtaCte"),
          meta: {
            titleApp: "<span class='darken-1'>Cuenta Corriente</span>"+
             "<span  class='darken-1 pa-left'>/</span>"+
            "<span  class='pa-left'></span>"+
            "Adquirir Saldo Cuenta Corriente",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$ADQUIRIR_SALDO_CTA_CTE],
          },
        },

        {
          name: "pedido_finalizado",
          path: "PedidoFinalizado",
          component: () => import("@/views/portal/PedidoFinalizado"),
          meta: {
            titleApp: "Venta Elementos Registrales - Pedido Finalizado",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES],
          },
        },
        // --------------- Flujo Elementos Registrale FIN ----------------------


        // --------------- Flujo Cuenta corriente INICIO --------------
        {
          name: "cuenta_corriente",
          titleApp: "Cuenta Corriente",
          path: "cuenta_corriente",
          component: () => import("@/views/portal/CuentaCorriente"),
          meta: {
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$LISTADO_CTA_CTE],
          },
        },

        {
          name: "mi_cuenta_corriente_consulta",
          path: "mi_cuenta_corriente_consulta",
          component: () => import("@/views/portal/MiCuentaCorrienteConsulta"),
          meta: {
            titleApp: "<span class='darken-1'>Cuenta Corriente / </span>Mi Cuenta Corriente",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            //permiso:[Vue.prototype.$LISTADO_CTA_CTE],
          },
        },
        // --------------- Flujo Cuenta corriente FIN --------------
        // --------------- Flujo Transacciones INICIO --------------
        {
          name: "transacciones",
          path: "transacciones",
          component: () => import("@/views/portal/Transacciones"),
          meta: {
            titleApp: "<span class='darken-1'>Transacciones / </span>Listado de Transacciones",
            requireAuth:true,
            store: true,
          },
        },

        {
          name: "planilla_caja",
          path: "planilla_caja",
          component: () => import("@/views/portal/PlanillaCaja"),
          meta: {
            titleApp: "<span class='darken-1'>Transacciones / </span>Planilla de Caja",
            requireAuth: true,
            store: true,
          },
        },

        {
          name: 'gestion_stock_estampillass',
          path: '/stock/estampillas',
          component: () => import('@/views/stock/StockEstampillas'),
          meta: {
            titleApp: "<span class='darken-1'>Stock</span>"+
            "<span  class='darken-1 pa-left'>/</span>"+
            "<span  class='pa-left'></span>"+
            "Gestión de Stock",
            //middleware: [propertiesSap],
            requireAuth:true,
            store: true,
            //permiso: "venderElementosAnmac",
          },
        },


        {
          name: "elementos_anmac_venta",
          path: "elementos_anmac_venta/:CardCode?",
          component: () => import("@/views/portal/estampillasAnmac/EstampillasAnmacVenta"),
          meta: {
            titleApp: "<span class='darken-1'>Vender</span>"+
            "<span  class='darken-1 pa-left'>/</span>"+
            "<span  class='pa-left'></span>"+
            "Vender Elementos ANMAC",
            //middleware: [propertiesSap],
            requireAuth:true,
            store: true,
            //permiso: "venderElementosAnmac",
          },
        },



        /*{
          name: "cliente_alta",
          path: "cliente_alta",
          component: () => import("@/views/portal/ClienteAlta"),
          meta: {
            titleApp: "Alta de Cliente Nuevo",
            //middleware: [propertiesSap],
            requireAuth:true,
            store: true,
            //permiso:'clientes_create',
            //permiso:'alta_cliente',
          },
        },*/
        {
          name: 'admin_solicitud_usuario',
          path: '/admin/admin_solicitud_usuario',
          component: () => import('@/views/cruds/SolicitudesUsuarios'),
          meta: {
            titleApp: 'Solicitudes Nuevo Concesionario',
            requireAuth: true,
            /*middleware: [propertiesSap],*/
            store: true,
            //permiso:'gestionar_solicitud_concesionario',
          },
        },
        {
          name: 'admin_zona_archivos',
          path: '/zona_archivos',
          component: () => import('@/views/cruds/ZonaDescagas'),
          meta: {
            titleApp: 'Zona Descargas',
            requireAuth: true,
            /*middleware: [propertiesSap],*/
            store: true,
            /*permiso:'listado_zona_descargas',*/
          },
        },

        {
          name: 'notificaciones_usuarios',
          path: '/notificaciones_usuarios',
          component: () => import('@/views/cruds/NotificacionesUsuarios'),
          meta: {
            titleApp: 'Notificaciones de Usuarios',
            requireAuth: true,
            /*middleware: [propertiesSap],*/
            store: true,
            //permiso:'solicitudes_usuarios_list',
          }
        },

        {
          name: 'admin_usuarios',
          titleApp: 'Usuarios',
          path: '/admin/Usuarios',
          component: () => import('@/views/user-admin/Usuarios'),
          meta: {
            titleApp: "<span class='darken-1'>Administrador / </span>Gestión de Usuarios",
            requireAuth: true,
            store: true,
          },

        },
        {
          name: 'emular_user',
          path: 'admin/emular_user',
          component: () => import("@/views/user-admin/EmularUser"),
          meta: {
            titleApp: "<span class='darken-1'>Administrador / </span>Emular usuarios",
            requireAuth:true,
            store: true,
          },
        },

        {
          name: 'GestionGrupos',
          titleApp: 'Gestión de Grupos',
          path: '/admin/GestionGrupos',
          component: () => import('@/views/user-admin/GestionGrupos'),
          meta: {
            titleApp: "<span class='darken-1'>Administrador / </span>Gestión de Grupos",
            requireAuth: true,
            store: true,
          },

        },

        {
          name: 'logErrores',
          titleApp: 'Log de Errores',
          path: '/admin/LogErrores',
          component: () => import('@/views/user-admin/LogErrores'),
          meta: {
            titleApp: "<span class='darken-1'>Administrador / </span>Log de Errores",
            requireAuth: true,
            store: true,
          },

        },
        {
          name: 'configs',
          path: 'admin/configuraciones',
          component: () => import("@/views/user-admin/Configuraciones"),
          meta: {
            titleApp: "<span class='darken-1'>Administrador / </span>Configuraciones",
            requireAuth:true,
            store: true,
          },
        },

        {
          name: 'chat_page',
          path: 'chat_page',
          component: () => import('@/views/dashboard/components/core/ChatPage'),
          meta: {
            titleApp: 'Chat',
            requireAuth: true,
          },
        },
        {
          name: 'perfil',
          path: 'perfil',
          component: () => import('@/views/dashboard/components/core/UserProfile'),
          meta: {
            titleApp: 'Mi Perfil',
            requireAuth: true,
          },
        },
        {
          path: 'changePassword',
          name: 'changePassword',
          component: () => import('@/views/auth/ChangePassword'),
          meta: {
            titleApp: 'Cambiar Contraseña',
            requireAuth: true,
          },
        },

        {
          name: 'ClientesAduana',
          titleApp: 'Gestión de Clientes Aduana',
          path: '/clientes/ClientesAduana',
          component: () => import('@/views/portal/ClientesAduana'),
          meta: {
            titleApp: "<span class='darken-1'>Clientes / </span>Aduana",
            requireAuth: true,
            store: true,
          },
        },
        {
          name: 'ClientesAnmac',
          titleApp: 'Gestión de Clientes ANMAC',
          path: '/clientes/ClientesAnmac',
          component: () => import('@/views/portal/ClientesAnmac'),
          meta: {
            titleApp: "<span class='darken-1'>Clientes / </span>ANMAC",
            requireAuth: true,
            store: true,
          },

        },




      ],
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('@/views/Unauthorized'),
      meta: {
        requireAuth: false,
      },

    },

    {
      path: '/noacceso/:tipo',
      name: 'noacceso',
      component: () => import('@/views/NoAcceso'),
      props: true, // Habilita el paso de params como props

      meta: {
        requireAuth: false,
         store: true,
      },

    },
    {
      path: "/solicitud_usuario_concesionario",
      name: "solicitud_usuario_concesionario",
      component: () => import("@/views/SolicitudUsuarioConcesionario"),
      meta: {
        titleApp: "Solicitudes Nuevo Concesionario",
        requireAuth: false,
      },

    },

    {
      name: 'SolicitudEstado',
      path: '/solicitud_estado/:id?',
      component: () => import('@/views/SolicitudEstado'),
      meta: {
        titleApp: 'Estado de Solicitud',
        requireAuth: false,
      },
    },
    /*{
      name: "home",
      path: "/",
      component: () => import("@/views/Home"),
      meta: {
        titleApp: "Home",
        requireAuth: false,
      },
    }
      {
        path: '/',
        redirect: '/portal',
        meta: {
          titleApp: "Home",
          requireAuth: true,
        },
      },*/



  ],
});



router.beforeEach((to, from, next) => {
  if (!to.meta.requireAuth) {
  next();
  }
  else if (to.meta.middleware) {
    const middlewarePromises = to.meta.middleware.map(middleware => {
      return new Promise((resolve, reject) => {
        middleware({ to, from, next: resolve, router, store });
      });
    });

    Promise.all(middlewarePromises)
      .then(() => {
        console.log("Todos los middlewares han sido ejecutados correctamente");
        next();
      })
      .catch(error => {
        console.error("Error al ejecutar los middlewares:", error);
        next(false);
      });
  } else {
    next();
  }
});

export default router;
