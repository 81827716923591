import Vue from "vue";
import App from "./App.vue";


import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueConfirmDialog from "vue-confirm-dialog";
import Keycloak from "keycloak-js";
import AxiosApi from "./plugins/axiosApi";

import ClientVar from "./utils/ClientVar";
import Can from "./utils/Can";

import Rules from "./utils/Rules.js";
import CrudRequest from "./utils/CrudRequest.js";
import formatters from "./utils/formatters";
//import Permissions from "@/mixins/Permissions";
import store from "./store";

import JsonExcel from "vue-json-excel";
import swal from "sweetalert2";

import VuetifyMoney from "vuetify-money";

//import { VueMaskDirective } from "v-mask";
import VueTheMask from 'vue-the-mask';

import moment from "moment";

import "animate.css";
import "./assets/styles/global.css";
import router from "./router";


Vue.prototype.moment = moment;

import VueToastr from "vue-toastr";
import startPusher from "./utils/Pusher";

// use plugin
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});

window.Swal = swal;

Vue.use(VuetifyMoney);
//Vue.directive("mask", VueMaskDirective);
Vue.use(VueTheMask);
Vue.use(ClientVar);
Vue.use(Can);
Vue.use(Rules);
Vue.use(CrudRequest);
Vue.use(formatters);

//Vue.mixin(Permissions);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
//Vue.component('f01-wizard-component', require('./views/tramites/components/F01Wizard.vue').default);
Vue.component(
  "filter-component",
  require("./views/components/Filter.vue").default
);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  Vue.prototype.$previousRoute = from;
  next();
});


//console.log(store.state.user);


const initApp = () => {
  const currentPath = window.location.pathname;
  const filteredRoute = findRoutesByPath(router.options.routes, currentPath);

  if (!filteredRoute || filteredRoute.meta.requireAuth) {
    initializeKeycloak();
  } else {
    startVueApp();
    Vue.prototype.$axiosApi = new AxiosApi();
  }
};

const initializeKeycloak = () => {
  let client = "";
  let urlKeycloak = process.env.VUE_APP_KEYCLOAK_URL;
  client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
  let initOptions = {
    url: urlKeycloak,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: client,
    onLoad: "login-required",
  };

  let keycloak = new Keycloak(initOptions);
  console.log(keycloak);
  keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
      console.log(auth);
      if (!auth) {
        console.warn("No autenticado.");
        //window.location.reload();
      } else {
        store.commit('SET_USER', keycloak);
        Vue.prototype.$keycloak = keycloak;
        Vue.prototype.$token = keycloak.token
        Vue.prototype.$axiosApi = new AxiosApi(Vue.prototype.$token);

        setInterval(refreshToken, 10000);
        startVueApp(keycloak);


      }



      //configureRouter();
    })
    .catch((err) => {
      console.log("Authentication Failed", err);
    });
};

const configureRouter = () => {
  router.beforeEach((to, from, next) => {
    if (to.meta.isAuthenticated) {
      const basePath = window.location.toString();
      if (!Vue.prototype.$keycloak.authenticated) {
        Vue.prototype.$keycloak.login({
          redirectUri: basePath.slice(0, -1) + to.path,
        });
      } else {
        Vue.prototype.$keycloak.updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token 1 refreshed:"+refreshed);
          } else {
            console.log("Token 1 not refreshed");
          }


            next();
          })
          .catch((err) => {
            console.error("Failed to update token", err);
          });
      }
    } else {
      next();
    }
  });
};

Vue.prototype.$sesionExpirada =false;
Vue.prototype.$mostrarAviso =true;
Vue.prototype.$tiempoAviso =60;

const refreshToken = () => {
         if (!Vue.prototype.$keycloak) return;

        let isTokenValid = tokenValid();



        if (isTokenValid ==0 && Vue.prototype.$sesionExpirada === false) {
            Vue.prototype.$sesionExpirada =true;
            sessionStorage.clear();
            store.dispatch('logout');
            console.log("Sesión expirada. Redirigiendo...");
            Vue.prototype.$keycloak.logout({redirectUri: window.location.origin  });
        }

        else if (isTokenValid ==3 && Vue.prototype.$mostrarAviso) {
            Vue.prototype.$mostrarAviso =false;

            let tiempoRestante =  segundosToken();

            Swal.fire({
                 html: `<div class="v-sheet theme--light" style="margin-top:30px">
                <button type="button" class="ml-6 mt-6 v-btn v-btn--fab v-btn--icon v-btn--round theme--dark elevation-0 v-size--x-large" style="pointer-events: none; background: rgba(255, 193, 7, 0.27);">
                <span class="v-btn__content">
                <i aria-hidden="true" class="v-icon notranslate text-center mdi mdi-alert-circle-outline theme--dark" style="color: rgb(255, 193, 7); caret-color: rgb(255, 193, 7); font-size: 55px; pointer-events: none;">
                </i></span>
                </button>

                <div style="margin-top:20px">
                  <p class="text-left" style="font-weight: 500; font-size: 18px;text-align:start;">¿Necesita más tiempo?</p>
                <br>
                <p style="font-weight: 400; color: rgb(125, 125, 125);text-align:start">
                Su sesión expira en <b>${tiempoRestante}</b> segundos</p></div>

                <br>`,

                allowOutsideClick: false,
                showConfirmButton: true,
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: true,
                confirmButtonColor: "#007bff",
                confirmButtonText: "Aceptar",
                customClass: {
                  confirmButton: 'custom-confirm-button' ,// Aplica la clase al botón de confirmación
                  actions: 'custom-swal-actions',
                  popup: 'custom-swal-popup',
                  content: 'custom-swal-content'
                }
          }).then((result) => {
            clearInterval(timer);

            if (result.isConfirmed) {

                Vue.prototype.$keycloak.updateToken(60).then((refreshed)=>{

                     let segundosRestantes = segundosToken();
                     let endtoken = endToken();
                    if (refreshed) {
                      Vue.prototype.$mostrarAviso =true;
                      Vue.prototype.$token = Vue.prototype.$keycloak.token;
                      Vue.prototype.$axiosApi.token = Vue.prototype.$keycloak.token;
                      console.log('Token:'+endtoken+', renovado, valido por '  + segundosRestantes + ' segundos');
                    } else {
                      console.log('Token:'+endtoken+', no renovado, valid por  '  + segundosRestantes + ' segundos');
                    }
                    Swal.close();
              }).catch(()=>{
                    console.log('Failed to refresh token');
              });
            }
          });

          // ****************************** actualizar el swal
          const timer = setInterval(() => {
              tiempoRestante--;
              if(tiempoRestante < 0)
              {
                tiempoRestante = 0;
              }
              Swal.update({
                html: `<div class="v-sheet theme--light" style="margin-top:30px">
                <button type="button" class="ml-6 mt-6 v-btn v-btn--fab v-btn--icon v-btn--round theme--dark elevation-0 v-size--x-large" style="pointer-events: none; background: rgba(255, 193, 7, 0.27);">
                <span class="v-btn__content">
                <i aria-hidden="true" class="v-icon notranslate text-center mdi mdi-alert-circle-outline theme--dark" style="color: rgb(255, 193, 7); caret-color: rgb(255, 193, 7); font-size: 55px; pointer-events: none;">
                </i></span>
                </button>

                <div style="margin-top:20px">
                  <p class="text-left" style="font-weight: 500; font-size: 18px;text-align:start;">¿Necesita más tiempo?</p>
                <br>
                <p style="font-weight: 400; color: rgb(125, 125, 125);text-align:start">
                Su sesión expira en <b>${tiempoRestante}</b> segundos</p></div>

                <br>`,

              });

              if (tiempoRestante <= 0) {
                clearInterval(timer);
                Swal.close();
                Vue.prototype.$sesionExpirada =true;
                sessionStorage.clear();
                store.dispatch('logout');
                console.log("Sesión expirada. Redirigiendo...");
                // Aquí puedes ejecutar la acción que quieras cuando expire el tiempo
                Vue.prototype.$keycloak.logout({redirectUri: window.location.origin  });

              }
            }, 1000);
            // ****************************** fin actualizar

      }





};


function tokenValid() {


      let segundosRestantes = segundosToken();
      let endtoken = endToken();

      

      if(store.state.user.sapProperties.U_BloqueoWeb == "tNO")
      {
            if(segundosRestantes >=60)
            {
              console.log('Token:'+endtoken+' - valido por '  + segundosRestantes + ' segundos');
              return 2; // no renovar
            }if(segundosRestantes <60 && segundosRestantes>Vue.prototype.$tiempoAviso)
            {
              console.log('Token:'+endtoken+' - para renovar en '  + segundosRestantes + ' segundos');
                return 1; //renovar
            }else if(segundosRestantes <Vue.prototype.$tiempoAviso && segundosRestantes>0)
            {
                console.log('Token:'+endtoken+' - aviso en '  + segundosRestantes + ' segundos');
                return 3; //aviso
            }
            else
            {

              console.log('Token:'+endtoken+' - expiro hace '+ segundosRestantes + ' segundos');
                return 0; //expiro
            }
      }
      else
      {
        console.log('Usuario Bloqueado no hace falta renovar token');

        return 4;
      }
        //return Date.now() > expTime - bufferTime;
    }




function segundosToken()
{
      const tokenData = JSON.parse(atob(Vue.prototype.$keycloak.token.split('.')[1]));
      const expTime = tokenData.exp * 1000;
      //const bufferTime =28*60*1000 ;
      const bufferTime = 0 ;

      const remainingTimeInMillis = expTime - bufferTime - new Date().getTime() ;
      return  Math.round(remainingTimeInMillis / 1000);

}
function endToken()
{
  let t = Vue.prototype.$keycloak.token.toString();
  return t.substring(t.length-5,t.length);
}


const startVueApp = (keycloak) => {
  const app = new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: (h) => h(App, { props: { keycloak: keycloak } }),
        data:{
          notifications: [],
        }
      }).$mount("#app");

      startPusher(app,keycloak);



};

function findRoutesByPath(routes, currentPath) {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    /*if (route.path === currentPath) {
      return route;
    } else if (route.children) {
      const childOption = findRoutesByPath(route.children, currentPath);
      if (childOption) {
        return childOption;
      }
    }*/
    // Convertir la ruta con parámetros dinámicos a una expresión regular
    const paramRegex = new RegExp('^' + route.path.replace(/:[^\s/]+/g, '([\\w-]+)') + '$');

    if (paramRegex.test(currentPath)) {
      return route;
    } else if (route.children) {
      const childOption = findRoutesByPath(route.children, currentPath);
      if (childOption) {
        return childOption;
      }
    }
  }
  return null;
}



initApp();


