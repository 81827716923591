import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import AxiosApi from "./plugins/axiosApi"
import router from "./router";

Vue.use(Vuex);

import carrito from "./modules/carrito";



export default new Vuex.Store({
  modules: {
    carrito,


  },

  plugins: [createPersistedState()],
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    /*barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',*/
    barImage: "",
    drawer: null,
    keyDrawer:0,
    authenticated: false,
    drawerLoading: true,
    drawerLoadingAdmin:true,
    config: [],
    user: {
      id:null,
      username:null,
      name:null,
      roles: Array,
      permisos: Array,
      entidades: Array,
      notificaciones: [],
      sapProperties:{},
      sapPropertiesAdmin:{}, // guardo las props previas al cambio de usuario
      userInfoAdmin:{}, // guardo las props previas al cambio de usuario
      emule: false, //flag para indicar que esta emulando un usuario
    },
    paymentMethods: [{
      code: 'SALDO',
      desc: "SALDO A FAVOR",
      //paymentkey: c_paymentMethods.saldo,
      no_charge: true,
      methods: [{
        //paymentkey: c_paymentMethods.saldo,
        GLAccount: '1231231',
        desc: 'Saldo a favor',
        PaymentMethodCode: 0,
        code: 'SALDO'
      }]
    },
    {
      code: 'BANKNACION',
      desc: "BOLETA BANCO NACIÓN",
      methods: [
        {
          GLAccount: '2222',
          desc: 'Boleta efectivo Banco Nación',
          no_charge: true,
          PaymentMethodCode: 0,
          code: 'BANKNACION_EFECTIVO'
        },
        {
          GLAccount: '3333',
          desc: 'Boleta cheque Banco Nación',
          no_charge: false,
          PaymentMethodCode: 0,
          code: 'BANKNACION_CHEQUE',
          // Se asume que `data.customer_info` ya está definido
          // noDisplay: data.customer_info.is_deleg_anmac
        }
      ]
    },
    {
      code: 'BTOB',
      desc: "B TO B",
      //paymentkey: c_paymentMethods.btob,
      no_charge: true,
      methods: [{
        //paymentkey: c_paymentMethods.btob,
        GLAccount: '1231231',
        desc: 'B to B (Transferencia bancaria)',
        PaymentMethodCode: 0,
        code: 'BTOB'
      }],
      cartEditPath: null,
    },
    {
      code: 'CREDITCARD',
      desc: "Tarjeta de crédito",
      no_charge: true,
      cartEditPath: null,
      methods: [{
        GLAccount: '1231231',
        desc: 'Tarjeta de crédito',
        PaymentMethodCode: 0,
        code: 'CREDITCARD'
      }]
    },
    {
        desc: 'Tarjeta de débito',
        code: 'DEBITCARD',
        methods: [{
          desc: 'Tarjeta de débito',
          code: 'DEBITCARD'
      }]
    },
    {
      desc: 'Transferencia',
      code: 'TRANSFER',
       methods: [{
          desc: 'Transferencia',
          code: 'TRANSFER'
      }]
    },
    {

      //GLAccount: '1231231',
      desc: 'Depósito personal',
      //PaymentMethodCode: 0,
      code: 'DEPOSIT',
       methods: [{
          desc: 'Depósito personal',
          code: 'DEPOSIT'
      }]
    }
    ],
    precioFlete: null, // Estado para almacenar el precio de flete
    conveniosRecaudacion: {},
  },
  mutations: {

    async SET_CONVENIOS_RECAUDACION(state, token) {
      try {
        const axiosApi = new AxiosApi(token);
        const response = await axiosApi.getByCriteria(
          "conveniosRecaudacion",
          process.env.VUE_APP_DB_MJ+","+process.env.VUE_APP_DB_ACARA
        );

        if (response.data && response.data.data) {
          state.conveniosRecaudacion = response.data.data;
        } else {
          state.conveniosRecaudacion = {}; // Vaciar si no hay datos
        }
      } catch (error) {
        console.error("Error al obtener convenios de recaudación:", error);
        state.conveniosRecaudacion = {}; // Vaciar en caso de error
      }
    },

    async SET_FETCH_PRECIO_FLETE(state, token) {
      try {
        const axiosApi = new AxiosApi(token);
        const response = await axiosApi.getByCriteria("itemsByCode", "FLE/"+process.env.VUE_APP_DB_ACARA);

        if (response.data && response.data.data && response.data.data.value) {
          const itemPrices = response.data.data.value[0].ItemPrices;
          if(typeof state.user.sapProperties.PriceListNum !=='undefined'){
            const userPriceListNum = (typeof state.user.sapProperties.PriceListNum !=='undefined')? state.user.sapProperties.PriceListNum:0;
            const shippingItem = itemPrices.find(
              (price) => price.PriceList === userPriceListNum.toString()
            );
            if (shippingItem) {
              const precio = parseFloat(shippingItem.Price);
              state.precioFlete = precio;
            } else {
              state.precioFlete = null;
            }
          }
          else{
            state.precioFlete = null;
          }



        }
      } catch (error) {
        console.error("Error al obtener el precio de flete:", error);
        state.precioFlete = null;
      }
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_BAR_COLOR(state, payload) {
      state.barColor = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    async SET_USER (state, payload){
      try{
        /*if(state.authenticated == true){
          return ;
        }*/
        let userId = payload.tokenParsed.sub;
        state.user.id = userId;
        state.user.username = payload.tokenParsed.preferred_username;
        state.user.name = payload.tokenParsed.given_name +
        " " +
        payload.tokenParsed.family_name;
        const client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
    
        if(payload.tokenParsed.groups){

          //console.log(payload.tokenParsed);
          /*const roleUser = payload.tokenParsed.groups.filter(item => item.substring(item.indexOf(' - ')+3) == client)
          .map(item => {
            const name = item.substring(1, item.indexOf(' - '));
            return item.startsWith('/') ? name : item.substring(0, item.indexOf(' - '));
          });
          state.user.roles = roleUser;*/
        }
        else{
          state.user.roles = [];
        }
        state.user.permisos = [];
        //console.log("state.user.permisos");
        //console.log(payload);
        if(payload.resourceAccess){
          for (const key in payload.resourceAccess) {
              if(key == client){
                state.user.permisos = payload.resourceAccess[key].roles;
                break;
              }
          }
        }
        else{
          state.user.permisos = [];
        }
        //busco la/s entidad/es        
        if(state.user.entidades.length < 1)
        {
            let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('usuarios_entidades', userId+'/'+client)
            .then((r) => {
              if(r.data.data){

                let arrayEntidades = r.data.data;
                let entidades = [];
                for (const k in arrayEntidades) {

                  entidades.push(arrayEntidades[k].entidad_id);

                }
                state.user.entidades = entidades;
              }
              else{
                state.user.entidades = []
              }

            })
            .catch(function (error) {
              console.log(error);
            });
        }
        //datos de usuarios

        let axiosApiUser = new AxiosApi(payload.token)
        axiosApiUser.getByCriteria('usuarios', userId)
            .then((r) => {
              if(r.data.data){
                 state.user.userInfo = r.data.data;
                  //console.log("state.user.userInfo");
                  //console.log(state.user.userInfo);


                let rol = state.user.userInfo.userRoles.filter(userRole => userRole.clients.name === "acara-portal") // Filtrar clientes con "acara-portal"
                .flatMap(userRole => userRole.role) // Extraer roles de los usuarios filtrados

                //console.log("state.user.userInfo");
                //console.log(rol[0]);
                state.user.roles = rol[0];

                  if(rol[0].activo ==='0') ////sitio en mantenimiento
                  {
                      console.log("sitio en mantenimiento para ese rol");
                      router.push({ path: '/noacceso/4'});
                  }
                  else
                  {
                      state.drawerLoadingAdmin = false;
                  }
              }

            })
            .catch(function (error) {
              console.log(error);
            });

        console.log("state");
        console.log(state);
        //state.user.notificaciones = [];
        let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('notificacionesByUser', userId)
            .then((r) => {
              if(r.data.data){
                state.user.notificaciones = r.data.data;
              }
              else{
                state.user.notificaciones = []
              }
            })
            .catch(function (error) {
              console.log(error);
            });

            return 1;
      }
      catch(error){
        return error;
      }


    },
    async SET_DATA_SAP(state, payload){
      try{

        if(state.authenticated == true){
          return ;
        }

      ///////////////////////////////////////////////////////////////////////////////
      // si es admin_general es distinto ya que no tengo que ir a SAP   a buscar las sapProperties
      ///////////////////////////////////////////////////////////////////////////////
      if(state.user.permisos.includes("admin_general"))
      {
        state.drawerLoading = false;

      }else if(typeof state.user.sapProperties.CardCode == 'undefined'   )
        {
            state.drawerLoading = true;

            const respSap = async (dataUser) => {
              let username = dataUser.tokenParsed.preferred_username;

              //let params = username.toUpperCase() + "/TST_MJV20";
              let params = username.toUpperCase();

              let axiosApiUser = new AxiosApi(payload.token)
              const response = await axiosApiUser.getByCriteria(
                'bussinessPartnerClient',
                params
              );

              return response.data.data;
            };
            let resp = await respSap(payload);
            if (resp) {


                  state.user.sapProperties = resp;

                  if(resp.Valid == true)
                  {
                      if(resp.U_BloqueoWeb == "tNO")
                      {
                          state.keyDrawer = + 1;
                      }

                      else
                      {
                        console.log("usuario bloqueado");
                        router.push({ path: '/noacceso/1'});

                      }
                  }
                  else
                  {
                    console.log("usuario inactivo");
                    router.push({ path: '/noacceso/3'});

                  }



            } else {
              state.user.sapProperties = {};
              state.keyDrawer = + 1;


            }

            state.drawerLoading = false;
        }
        else{
             state.drawerLoading = false;

        }
      }
      catch (error) {
        console.log(error);
      }

    },
    async SET_EMULE_DATA_SAP(state, payload){
      try{

        if(payload){

          //guardo las propsSap previas a la emulacion
          state.user.sapPropertiesAdmin = state.user.sapProperties;
          state.user.userInfoAdmin = state.user.userInfo;
          //activo la emulacion
          state.user.emule = true;

          state.drawerLoading = true;
          state.user.sapProperties = payload;
          state.drawerLoading = false;
          state.user.userInfo = {};

          let username = payload.CardCode;
          state.user.username = username;
          state.user.name = payload.CarName;

          let axiosApiUser = new AxiosApi(payload.token)
          axiosApiUser.getByCriteria('usuarioByUsername', username)
              .then((r) => {
                console.log(r.data);
                if(r.data.data){
                  state.user.userInfo = r.data.data;
                  const client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
                  if(state.user.userInfo.userRoles){
                    if(state.user.userInfo.userRoles[0].role){
                      state.user.roles = [state.user.userInfo.userRoles[0].role[0].roleName];
                    }
                    else{
                      state.user.roles = [];
                    }
                  }
                  else{
                    state.user.roles = [];
                  }

                  state.drawerLoadingAdmin = false;
                }

              })
              .catch(function (error) {
                console.log(error);
              });

          //console.log(state.user.sapProperties);
        }
        else {
          state.user.sapProperties = {};
          //state.keyDrawer = + 1;
          state.drawerLoading = false;
        }
      }
      catch (error) {
        console.log(error);
      }

    },
    async LOGOUT_EMULE(state){
      state.user.sapProperties = state.user.sapPropertiesAdmin;
      state.user.userInfo = state.user.userInfoAdmin;
      state.user.sapPropertiesAdmin = {};
      state.user.userInfoAdmin = {};
      state.user.emule = false;
    },
    async SET_EMULE_USER (state, payload){
      try{
        let userId = state.user.userInfo.id;


        state.user.permisos = [];

        if(payload.resourceAccess){
          for (const key in payload.resourceAccess) {
              if(key == client){
                state.user.permisos = payload.resourceAccess[key].roles;
                break;
              }
          }
        }
        else{
          state.user.permisos = [];
        }
        //busco la/s entidad/es

        if(state.user.entidades.length < 1)
        {
            let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('usuarios_entidades', userId+'/'+client)
            .then((r) => {
              if(r.data.data){

                let arrayEntidades = r.data.data;
                let entidades = [];
                for (const k in arrayEntidades) {

                  entidades.push(arrayEntidades[k].entidad_id);

                }
                state.user.entidades = entidades;
              }
              else{
                state.user.entidades = []
              }

            })
            .catch(function (error) {
              console.log(error);
            });
        }

      }
      catch(error){
        return error;
      }


    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_CART_EDIT_PATH(state, value) {
      state.cartEditPath = value;
    },
    RESET_USER (state){
      state.user.id = null;
      state.user.username = null,
      state.user.name = null;
      state.user.roles =[];
      state.user.permisos = [];
      state.user.entidades = [];
      state.user.notificaciones = [];
      state.user.userInfo = [];
      state.drawerLoading= true;
      state.drawerLoadingAdmin =true;
      state.user.sapProperties={};
      state.user.sapPropertiesAdmin = {};
      state.user.userInfoAdmin = {};
      state.user.emule = false;
      state.carrito = {
        data: [], // Inicializado como un arreglo vacío
        cantItemTotales: 0,
        cantItem: 0,
        subtotal: 0,
        gastos:0,
        total: 0,
        cliente:{}
      }


    },
    ADD_NOTIFICATION(state, notification) {
      state.user.notificaciones.push(notification);
    },

    async SET_CONFIG (state, payload){
      try{

          let axiosApi = new AxiosApi(payload.token)
          axiosApi.get('configuraciones')
            .then((r) => {
              if(r.data){

                let arrayConfig = r.data.data;
                let config = [];
                for (const item of arrayConfig) {
                  config[item.clave] = item.valor;
                }
                state.config = config;
              }
              else{
                state.config = []
              }

            })
            .catch(function (error) {
              console.log(error);
            });
        }
        catch(error){
          return error;
        }

      }




  },



  actions: {



    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    logout({ commit }) {
      commit('RESET_USER',{})
      commit('SET_AUTHENTICATED',false)
      commit('carrito/remove', {})
      //commit('can/remove', {})

    },
    async login({ commit }) {
      let client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
      if(Vue.prototype.$keycloak)
      {
          console.log(Vue.prototype.$keycloak);

          await commit('SET_DATA_SAP',Vue.prototype.$keycloak);                    
          await commit('SET_USER',Vue.prototype.$keycloak);
          await commit('SET_CONFIG',Vue.prototype.$keycloak);
          commit('SET_AUTHENTICATED',true);

          // Llamada para obtener el precio de flete después de autenticar al usuario
          await commit("SET_FETCH_PRECIO_FLETE", Vue.prototype.$keycloak.token);
          await commit("SET_CONVENIOS_RECAUDACION", Vue.prototype.$keycloak.token);

          return 1;





      }else{
        return 0;
      }

    },
    async emuleUser({ commit }, userDataEmule) {
      console.log('ingresa emule');
      console.log(userDataEmule);
      if(userDataEmule){
          Vue.prototype.$keycloak.tokenParsed.sub = userDataEmule.CardCode;
          Vue.prototype.$keycloak.tokenParsed.preferred_username = userDataEmule.CardCode;
          userDataEmule.token = Vue.prototype.$keycloak.token;
          await commit('SET_EMULE_DATA_SAP', userDataEmule);
          await commit('SET_EMULE_USER',userDataEmule);
          //await commit('SET_CONFIG',Vue.prototype.$keycloak);
          //commit('SET_AUTHENTICATED',true);

          // Llamada para obtener el precio de flete después de autenticar al usuario
          await commit("SET_FETCH_PRECIO_FLETE", Vue.prototype.$keycloak.token);
          await commit("SET_CONVENIOS_RECAUDACION", Vue.prototype.$keycloak.token);

          return 1;
      }
      else{
        return 0;
      }


    },

    async logoutEmule({ commit }) {

      await commit('LOGOUT_EMULE');
      commit('SET_AUTHENTICATED',false)
      commit('carrito/remove', {})
    },
  },
  getters: {
    isLogged: (state) => !!state.user,
    authenticated(state) {
      return state.authenticated;
    },
    getPaymentMethods(state) {
      const options = state.paymentMethods.flatMap(method => {
        return method.methods.map(subMethod => ({
          groupCode: method.code,
          code: subMethod.code,
          desc: subMethod.desc
        }));
      });

      // Devuelve ordenado alfabéticamente
      return options.sort((a, b) => a.desc.localeCompare(b.desc));
    },
    getPrecioFlete(state) {
      return state.precioFlete; // Para obtener el precio de flete
    },
    getSapProperties(state)
    {
      return  state.user.sapProperties;
    }
  },
});
