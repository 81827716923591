export const apiRoute = Object.freeze({


    updatedatosusuario:"api/keycloak/usuarios/updatedatosusuario",
    usuarios_enable:"api/keycloak/usuarios/changestatus",
    usuarios_sessions:"api/keycloak/usuarios/usersessions",
    roles:"api/keycloak/roles",
    users: "api/users",
    getUser:"api/getUser",
    userAuthRoute: "api/auth",
    usuarios_entidades:"api/keycloak/showByUserClient",
    usuarios:"api/keycloak/usuarios",
    usuarioByUsername:"api/keycloak/usuarioByUsername",
    solicitudUsuario:"api/keycloak/solicitud_usuario",
    solicitudUsuarioPublic:"api/keycloak/solicitud_usuario_public",
    solicitudUsuarioChangeStatus:"api/keycloak/solicitud_usuario/changestatus",
    identifiertypes: "api/keycloak/persons/identifiertypes/public",
    genders: process.env.VUE_APP_API_URL_PERSONS+"persons/genders",
    maritalstatuses: process.env.VUE_APP_API_URL_PERSONS+"persons/maritalstatuses",
    persons: process.env.VUE_APP_API_URL_PERSONS+"persons",
    personsAggregator: process.env.VUE_APP_API_URL_PERSONS+"personsaggregator",
    personRelationship: process.env.VUE_APP_API_URL_PERSONS+"persons/relationships",
    paises: "api/domicilios/paises",
    clientes: process.env.VUE_APP_API_URL+"/api/clientes",
    localidades: process.env.VUE_APP_API_URL+"/api/localidades",
    provincias: "api/provincias/public",
    notificacionesByUser:"api/notificaciones/unreadsByUser",
    notificacionesUsuarios:"api/notificaciones/notificationsByUser",
    notificacionesChangeStatus:"api/notificaciones/notificaciones_usuarios/changestatus",
    entidades:"api/entidades/entidades",
    stockEstampillas:"",
    stockEstampillasChangeStatus:"",
    /** url para el mapeo de stock de productos */
    asignarLote: "api/stock/asignarLote",
    stockByEntidadByProducto: "api/stock/lotes/entidad/producto",
    stockLotes: "api/stock/lotes",
    stockSerieByEntidadByProducto: "api/stock/lotes_formularios/entidad/producto",
    stockLotesFormularios: "api/stock/lotes_formularios",
    transacciones: process.env.VUE_APP_API_URL+"/api/transacciones",
    planillaCaja: process.env.VUE_APP_API_URL+"/api/planilla-caja",
    planillaCajaAdmin: process.env.VUE_APP_API_URL+"/api/planilla-caja/admin",
    transaccionesByUser: process.env.VUE_APP_API_URL+"/api/transaccionesbyuser",
    transaccionDetalle: process.env.VUE_APP_API_URL + "/api/transacciones/{id}/transaccion_detalle",
    uploadimageprofile:"api/keycloak/usuarios/uploadimageprofile",
    viewimageprofile:"api/keycloak/usuarios/viewimageprofile",
    tipo_entidades:"api/entidades/tipo_entidades",
    archivos:"api/archivos",
    archivos_enable:"api/archivos/changestatus",
    archivos_removefile:"api/archivos/removefile",
    archivos_getfile:"api/archivos/getfile",
    historialByProducto: "api/stock/historial/producto",
    bussinessPartnerSL: "api/servicelayer/bussinesspartners/TST_MJV20", // crea el usuario de SAP en servicelayer
    bussinessPartnerClient: "api/hanaxs/bussinesspartnerclient", //trae la info del usuario en SAP
    bussinessPartnerClientSearch: "api/hanaxs/bussinesspartners/clientes/search", //trae la info del usuario en SAP
    bussinessPartnerIsClient: "api/hanaxs/public/isclient", //verifica si existe el usuario en SAP
    solicitudUsuarioApprove : "api/keycloak/solicitud_usuario/approveUserRequest",
    //elementosRegistrales: "api/servicelayer/elementosregistrales", // consulta los items de elem registrales
    elementosRegistrales: "api/hanaxs/elementosregistrales", // consulta los items de elem registrales
    elementosAduana: "api/hanaxs/elementosaduana", // consulta los items de elem aduana
    elementosAnmac: "api/hanaxs/elementosanmac", // consulta los items de elem anmac
    elementosAnmacByWarehouse: "api/hanaxs/elementosanmacbywarehouse", // consulta los items de elem anmac

    isUser: "api/keycloak/userByEMail", //verifica si existe el usuario en Portal por su email
    isUserByUsername: "api/keycloak/userByUsername", //verifica si existe el usuario en Portal por su username
    aportes: "api/servicelayer/itemsaportes", //busca los valores de los aportes de los items en servicelayer
    banksMj: "api/hanaxs/banks/"+process.env.VUE_APP_DB_MJ, //busca los bancos de hanaxs
    banksAcara: "api/hanaxs/banks/"+process.env.VUE_APP_DB_ACARA, //busca los bancos de hanaxs
    banksAnmac: "api/hanaxs/banks/"+process.env.VUE_APP_DB_ANMAC, //busca los bancos de hanaxs
    creditCardsAnmac: "api/hanaxs/creditcards/"+process.env.VUE_APP_DB_ANMAC, //busca los bancos de hanaxs
    configuraciones: "api/configuraciones",
    locationCodeMatch: "api/locationcodematch/internalCode", //busca el codigo a partir del codigo interno
    locationCodeMatchExt: "api/locationcodematch/externalCode", //busca el codigo a partir del codigo interno
    itemsByCode: "api/hanaxs/itemsByCode", // Ruta para el endpoint de itemsByCode
    warehousesByCode: "api/hanaxs/warehousesByCode", // Ruta para warehousesByCode
    facturas: "api/servicelayer/facturas",//ruta para registrar facturas
    facturasAnmac: "api/stock/portalwebservice/facturasanmac",//ruta para registrar facturas de anmac
    saldoCuentaCorriente: "api/hanaxs/saldoscuentacorriente", // Ruta para obtener saldos cta cte
    conveniosRecaudacion: "api/servicelayer/conveniosRecaudacion", // Ruta para obtener convenios
    elementosAdquirirSaldoCC: "api/hanaxs/elementosadquirirsaldoctacte", // consulta los items de adquirir saldo en cc
    crearEditarClientes: "api/servicelayer/bussinesspartners",
    ordenes: "api/servicelayer/ordenes",//ruta para registrar ordenes
    ordenesAnmac: "api/servicelayer/ordenesanmac",//ruta para registrar ordenes de anmac
    allInvoices: "api/hanaxs/allinvoices", // Ruta para obtener todos los invoices
    currentAccount: "api/hanaxs/currentaccount", // Ruta para obtener todos los invoices
    currentAccountExport: "api/hanaxs/currentaccountexport", // Ruta para obtener todos los invoices
    tiposEstampillas: "api/hanaxs/tiposestampillas", // Ruta para obtener los tipos de estampillas

    //stockByWarehouse: "api/servicelayer/itemsbywarehouse", // consulta el stock de estampillas por almacen
    stockByWarehouse: "api/stock/stock/warehouse", // consulta el stock de estampillas por almacen
    stockPdf:"api/stock/stock/pdf",
    seriesReport: "api/stock/portalwebservice/seriesreport", // consulta el historial de una estampilla
    itemBySerie: "api/stock/portalwebservice/item", // consulta una estampilla por su numero de serie
    itemsByLote: "api/stock/portalwebservice/itemslote", // consulta un lote de estampillas

    stockTransfer: "api/stock/portalwebservice/transfer", // realiza la transferencia de stock entre almacenes
    seriesReportPdf: "api/stock/portalwebservice/seriesreportpdf",
    inventoryTransfer: "api/stock/portalwebservice/inventorytransfer", // consulta el ingreso de estampilla
    inventoryTransferPost: "api/stock/portalwebservice/inventorytransfer", // consulta transferencia de estampilla
    reposicionchapasdominio:"api/servicelayer/reposicionchapasdominio/"+process.env.VUE_APP_DB_MJ,

    // rutas para user-admin
    clients: "api/keycloak/clients",
    entidadByRole: "api/entidades/entidades_roles/entidadByRole",
    entidades_enable: "api/entidades/entidades/changestatus",
    entidades_roles: "api/entidades/entidades_roles",
    entidades_roles_enable: "api/entidades/entidades_roles/changestatus",
    permissions: "api/keycloak/permissions",
    permissions_roles: "api/keycloak/roles/permissions",
    roles_client: "api/keycloak/roles/client",
    roles_list: "api/keycloak/roles",
    gestionGrupos: "api/keycloak/roles_portal",
    gestionGrupos_enable:"api/keycloak/roles_portal/changestatus",
    gestionGrupos_enable_all:"api/keycloak/roles_portal/changestatusall",
    tipo_entidades_enable: "api/entidades/tipo_entidades/changestatus",
    auditLogSearch: "api/audit-log/search",
    auditLogExport: "api/audit-log/export",
    auditShow:"api/audit-log/show",
    cancelOrders: "api/servicelayer/orderscancel", //ruta a sl para cancelar ordenes
    cancelDrafts: "api/servicelayer/draftscancel", //ruta a sl para cancelar facturas borrador
    cancelInvoices: "api/servicelayer/invoicescancel", //ruta a sl para cancelar facturas invoices
    cancelInvoicesPws: "api/stock/portalwebservice/invoicescancel", //ruta a sl para cancelar facturas invoices en pws
    cancelIncoimingPaymentsPws: "api/stock/portalwebservice/incoimingpaymentscancel", //ruta a sl para cancelar pagos en pws

    facturarVenta: "api/stock/portalwebservice/facturarventaanmac",//ruta para registrar una factura anmac desde una orden de venta

    incomingPayments: "api/servicelayer/incomingpayments",
    chartAccounts:    "api/servicelayer/chartaccounts",
    creditCards:      "api/servicelayer/creditcards",
    delegacionesAnmac:"api/hanaxs/getdelegacionesanmac", //obtiene las delegaciones anmac
    delegacionesAduana:"api/servicelayer/getdelegacionesaduana", //obtiene las delegaciones aduana

    storeUserPortal:"api/keycloak/usuarios/userportal",
    rolesPortal: "api/keycloak/roles_portal_all",
    updatePass:"api/keycloak/usuarios/updatePassword",
    configs: "api/configuraciones"
});

